/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import ReactEcharts                   from 'echarts-for-react';
import echarts                        from 'echarts';
import currency                       from 'util/currency';
import Switch                         from '@material-ui/core/Switch';
import FormControl                    from '@material-ui/core/FormControl';
import FormGroup                      from '@material-ui/core/FormGroup';
import FormControlLabel               from '@material-ui/core/FormControlLabel';
import Checkbox                       from '@material-ui/core/Checkbox';
import withStyles                     from '@material-ui/core/styles/withStyles';
import {Typography}                   from "@material-ui/core";

const styles = theme => ({
    formControl: {
        display: 'block',
        marginTop: theme.spacing(1),
    },
    checkboxGroup: {
        display: 'flex',
        flexDirection: 'row',
    },
    diagramSort: {
        paddingRight: theme.spacing(1),
    }
})

const ProductsDiagram = ({ data, classes, hideFilter }) => {
    const [initialData, setInitialData] = useState([]);
    const [diagramData, setDiagramData] = useState([]);
    const [diagramSort, setDiagramSort] = useState(false);
    const [revenue, setRevenue] = useState(0);
    const [purchase, setPurchase] = useState(0);
    const [filters, setFilters] = useState({
        combo: true,
        susiCombo: true
    });

    useEffect(() => {
        let dataArr = data;
        if (!diagramSort) {
            dataArr = dataArr.map((item) => {
                return {
                    ...item,
                    value: +item.revenue
                }
            })
        } else {
            dataArr = dataArr.map((item) => {
                return {
                    ...item,
                    value: +item.quantity
                }
            });
        }
        dataArr = dataArr.sort((a, b) => Number(a.value) > Number(b.value) ? 1 : -1).reverse();
        setInitialData(dataArr);
        setDiagramData(dataArr);
    }, [data, diagramSort])

    const option = {
        legend: {
            data: diagramData.map((item) => item.name)
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow',
            },
            formatter: function (params) {
                if (params.length) {
                    let param = params[0];
                    return `
            ${param.name} 
            <br/> 
            Количество: ${diagramSort ? param.value : param.data.quantity}
            <br/>
            Выручка: ${currency(diagramSort ? param.data.revenue : param.value)}
            <br/>
            ${param.data.purchase ? `Себестоимость: ${currency(param.data.purchase)}` : ''}`
                }
            },
            backgroundColor: '#ff9e43',
            borderColor: 'white',
        },

        xAxis: {
            data: diagramData.map((item) => item.name),
            axisLabel: {
                interval: 0,
                rotate: 90,
                inside: true,
                textStyle: {
                    color: 'black'
                }
            },
            axisTick: {
                show: false
            },
            axisLine: {
                show: false
            },
            z: 10
        },
        yAxis: {
            axisLine: {
                show: false
            },
            axisTick: {
                show: false
            },
            axisLabel: {
                textStyle: {
                    color: '#999'
                }
            }
        },
        dataZoom: [
            {
                type: 'inside'
            }
        ],
        series: [
            {
                type: 'bar',
                itemStyle: {
                    color: "#9264d9"
                },
                emphasis: {
                    itemStyle: {
                        color: new echarts.graphic.LinearGradient(
                            0, 0, 0, 1,
                            [
                                { offset: 0, color: '#bd9de0' },
                                { offset: 0.5, color: '#af93cf' },
                                { offset: 1, color: '#cfbce6' }
                            ]
                        )
                    }
                },
                data: diagramData
            }
        ]
    }

    const handleChange = (e) => {
        setFilters({
            ...filters,
            [e.target.name]: e.target.checked
        })
    }

    const handleChangeSort = () => {
        setDiagramSort(prev => { return !prev });
    }

    useEffect(() => {
        let data = initialData;
        if (!filters.combo) {
            data = data.filter((item) => item.susi_combo === true);
        }

        if (!filters.susiCombo) {
            data = data.filter((item) => item.susi_combo === false)
        }
        data = data.sort((a, b) => Number(a.value) > Number(b.value) ? 1 : -1).reverse();
        let rev = 0;
        let pur = 0;
        data.map(item => {
            if (item.revenue) {
                rev += parseFloat(item.revenue)
            }

            if (item.purchase) {
                pur += parseFloat(item.purchase)
            }

        })
        setRevenue(rev.toFixed(2));
        setPurchase(pur.toFixed(2));
        setDiagramData(data);
    }, [filters, initialData])

    return (
        <>
            {
                !hideFilter ? <FormControl component="fieldset" className={classes.formControl}>
                    <FormGroup className={classes.checkboxGroup}>
                        <FormControlLabel
                            control={<Checkbox checked={filters.combo} onChange={handleChange} name="combo" />}
                            label="Комбо"
                        />
                        <FormControlLabel
                            control={<Checkbox checked={filters.susiCombo} onChange={handleChange} name="susiCombo" />}
                            label="Сеты"
                        />
                    </FormGroup>
                </FormControl> : null
            }
            <Typography variant={'h6'}>Выручка: {currency(revenue)}</Typography>
            <Typography variant={'h6'}>Себестоимость: {currency(purchase)}</Typography>
            <FormControlLabel className={classes.diagramSort}
                              control={<Switch checked={diagramSort} onChange={handleChangeSort} />}
                              label="По количеству"
            />
            <ReactEcharts
                style={{ height: '450px' }}
                option={option}
                opts={{ renderer: 'svg' }}
            />
        </>
    )
}

export default withStyles(styles)(ProductsDiagram)
